import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"
import BlogV3 from "../components/blogv3"
import "../styles/index.scss"

// Import Fonts
import "../fonts/CoasterShadow.ttf"
import "../fonts/COASBL.ttf"

/*

         aaronaaronaaron         aaronaaronaaron        aaronaaronaaron       aaronaaronaaron                      aaron
        aaronaaronaaron         aaronaaronaaron        aaronaaronaaron       aaronaaronaaron      aaron           aaron 
       aaron     aaron         aaron     aaron        aaron     aaron       aaronaaronaaron      aaron           aaron
      aaron     aaron         aaron     aaron        aaron     aaron       aaron     aaron      aaronaaron      aaron
     aaronaaronaaron         aaronaaronaaron        aaronaaronaaron       aaron     aaron      aaron  aaron    aaron
    aaronaaronaaron         aaronaaronaaron        aaron aaron           aaron     aaron      aaron    aaron  aaron
   aaron     aaron         aaron     aaron        aaron   aaron         aaronaaronaaron      aaron      aaronaaron
  aaron     aaron         aaron     aaron        aaron      aaron      aaronaaronaaron      aaron          aaron
 aaron     aaron         aaron     aaron        aaron        aaron    aaronaaronaaron      aaron         
    
    */

const Home = () => {
  return (
    <Layout>
      <Head title="Home" />
      <div className="mainDiv">
        <BlogV3 />
      </div>
    </Layout>
  )
}

export default Home
