import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as blogStyles from "./blog.module.scss"
import "../styles/index.scss"

import { OutboundLink } from "gatsby-plugin-google-analytics"

const MusicComponent = ({ song }) => {
  return (
    <div className={`${blogStyles.blogDiv}`}>
      <div className={`${blogStyles.blogPost}`}>
        <img src={song.image.file.url} alt={song.image.description} />
        <br />
        <OutboundLink
          href={song.musicUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="unusualButton">{song.message}</button>
        </OutboundLink>
      </div>
    </div>
  )
}

const LoosieComponent = ({ loosie }) => {
  return (
    <div className={`${blogStyles.blogDiv}`}>
      <div className={`${blogStyles.blogPost}`}>
        <h2>{loosie.audioFile.title}</h2>
        <audio controls>
          <source src={loosie.audioFile.file.url} type="audio/mpeg" />
        </audio>
      </div>
    </div>
  )
}

const ImageComponent = ({ image }) => {
  return (
    <div className={`${blogStyles.blogDiv}`}>
      <div className={`${blogStyles.blogPost}`}>
        <img src={image.image.file.url} alt={image.image.description} />
      </div>
    </div>
  )
}

const VideoComponent = ({ video }) => {
  return (
    <div className={`${blogStyles.blogDiv}`}>
      <div className={`${blogStyles.blogPost}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: video.embedURL,
          }}
        />
      </div>
    </div>
  )
}

/*
const ZipComponent = ({ zip }) => {
  return (
    <div className={`${blogStyles.blogDiv}`}>
      <div className={`${blogStyles.blogPost}`}>
        <h2>{zip.file.file.fileName}</h2>
        <OutboundLink href={zip.file.file.url} download>
          <button className="unusualButton">Download</button>
        </OutboundLink>
      </div>
    </div>
  )
}
*/

//GraphQL Query
const BlogV3 = () => {
  const data = useStaticQuery(graphql`
    query {
      music: allContentfulMusicAndArt(sort: { fields: date, order: DESC }) {
        nodes {
          type: __typename
          date
          image {
            description
            file {
              url
            }
          }
          musicUrl
          message
        }
      }
      loosie: allContentfulAudioFile(sort: { fields: date, order: DESC }) {
        nodes {
          type: __typename
          date
          audioFile {
            title
            description
            file {
              url
            }
          }
        }
      }
      images: allContentfulImage(sort: { fields: date, order: DESC }) {
        nodes {
          type: __typename
          date
          image {
            description
            file {
              url
            }
          }
        }
      }
      videos: allContentfulVideoEmbeds(sort: { fields: date, order: DESC }) {
        nodes {
          type: __typename
          date
          embedURL
        }
      }
    }
  `)

  // Creating the array of components
  const components = [
    ...data.music.nodes.map(m => ({
      date: m.date,
      component: <MusicComponent song={m} />,
    })),
   
    ...data.loosie.nodes.map(l => ({
      date: l.date,
      component: <LoosieComponent loosie={l} />,
    })),
    
    ...data.images.nodes.map(i => ({
      date: i.date,
      component: <ImageComponent image={i} />,
    })),
    ...data.videos.nodes.map(v => ({
      date: v.date,
      component: <VideoComponent video={v} />,
    })),
    /*
    ...data.zip.nodes.map(z => ({
      date: z.date,
      component: <ZipComponent zip={z} />,
    })),
    */
  ]

  // Sort the components LIFO
  const sortedComponents = components.sort(function compare(a, b) {
    var dateA = new Date(a.date)
    var dateB = new Date(b.date)
    return dateB - dateA
  })

  // Mapping out the array
  return <div>{sortedComponents.map(c => c.component)}</div>
}

export default BlogV3
